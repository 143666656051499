import { DesirabilityFunctionType } from '@discngine/moosa-models';

import { ReactComponent as CustomFunctionIcon } from './resources/custom-curve.svg';
import { ReactComponent as DiscreteFunctionIcon } from './resources/discrete.svg';
import { ReactComponent as LinearFunctionIcon } from './resources/linear.svg';
import { ReactComponent as LogarithmicFunctionIcon } from './resources/logarithmic.svg';
import { ReactComponent as RectangularFunctionIcon } from './resources/rectangular.svg';
import { ReactComponent as SigmoidFunctionIcon } from './resources/sigmoid.svg';
import { ReactComponent as TriangularFunctionIcon } from './resources/triangular.svg';
import { ReactComponent as UnitStepFunctionIcon } from './resources/unitstep.svg';

export const getIcon = (type: DesirabilityFunctionType): JSX.Element => {
  switch (type) {
    case DesirabilityFunctionType.linear:
      return <LinearFunctionIcon />;
    case DesirabilityFunctionType.triangular:
      return <TriangularFunctionIcon />;
    case DesirabilityFunctionType.rectangular:
      return <RectangularFunctionIcon />;
    case DesirabilityFunctionType.logarithmic:
      return <LogarithmicFunctionIcon />;
    case DesirabilityFunctionType.sigmoid:
      return <SigmoidFunctionIcon />;
    case DesirabilityFunctionType.unitStep:
      return <UnitStepFunctionIcon />;
    case DesirabilityFunctionType.discrete:
      return <DiscreteFunctionIcon />;
    case DesirabilityFunctionType.custom:
      return <CustomFunctionIcon />;

    default:
      ((x: never) => {
        throw new Error('Unexpected desirability function type');
      })(type);
  }
};
