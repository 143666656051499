import { DecisionTree, DTNodeId } from '@discngine/moosa-models';

interface GraphNode {
  id: DTNodeId;
  in: Set<DTNodeId>;
  out: Set<DTNodeId>;
}

type Graph = Map<DTNodeId, GraphNode>;

function dtToGraph(dt: DecisionTree): Graph {
  const graph: Graph = new Map<DTNodeId, GraphNode>();

  for (const node of dt.nodes) {
    const dagNode: GraphNode = {
      id: node.id,
      in: new Set(),
      out: new Set(),
    };

    graph.set(node.id, dagNode);
  }

  // fill in and out
  dt.arrows.forEach((arrow) => {
    const from = graph.get(arrow.from);

    if (!from) {
      throw new Error(`No node ${arrow.from} for arrow ${arrow.id}`);
    }
    from.out.add(arrow.to);

    const to = graph.get(arrow.to);

    if (!to) {
      throw new Error(`No node ${arrow.to} for arrow ${arrow.id}`);
    }
    to.in.add(arrow.from);
  });

  return graph;
}

export function sortDecisionTreeTopologically(dt: DecisionTree): {
  nodeIds: DTNodeId[];
  isCyclicGraph: boolean;
} {
  //   L ← Empty list that will contain the sorted elements
  //   S ← Set of all nodes with no incoming edge
  //
  //   while S is not empty do
  //     remove a node n from S
  //     add n to L
  //     for each node m with an edge e from n to m do
  //       remove edge e from the graph
  //       if m has no other incoming edges then
  //          insert m into S
  //
  //   if graph has edges then
  //      return error   (graph has at least one cycle)
  //   else
  //     return L   (a topologically sorted order)
  const graph = dtToGraph(dt);

  const sorted: DTNodeId[] = [];
  const startingNodes: DTNodeId[] = [...graph.values()]
    .filter((node) => node.in.size === 0)
    .map((node) => node.id);

  while (startingNodes.length) {
    const nodeId = startingNodes.shift()!;

    sorted.push(nodeId);

    const node = graph.get(nodeId);

    if (!node) {
      throw new Error(`Unexpectedly no node for ${nodeId}`);
    }

    const outNodes = [...node.out.values()];

    for (const nextNodeId of outNodes) {
      const nextNode = graph.get(nextNodeId)!;

      // remove arrow
      node.out.delete(nextNodeId);
      nextNode.in.delete(nodeId);

      if (!nextNode.in.size) {
        startingNodes.push(nextNode.id);
      }
    }
  }

  const isCyclicGraph = [...graph.values()].some((node) => !!node.in.size);

  return {
    nodeIds: sorted,
    isCyclicGraph,
  };
}
