import { IDesirabilityFunction, IMoosaDataService } from '@discngine/moosa-models';

import { createListSlice } from '@discngine/moosa-store/createListSlice';

import { DataProvider } from '../common';

export const DESIRABILITY_FUNCTION_SLICE_NAME = 'desirabilityFunction' as const;

export const desirabilityFunctionsSlice = createListSlice<
  typeof DESIRABILITY_FUNCTION_SLICE_NAME,
  IDesirabilityFunction,
  Parameters<NonNullable<IMoosaDataService['getDesirabilityFunctionList']>>[0]
>({
  sliceName: DESIRABILITY_FUNCTION_SLICE_NAME,
});

type DesirabilityFunctionsDataProvider = DataProvider<
  IDesirabilityFunction,
  Parameters<NonNullable<IMoosaDataService['getDesirabilityFunctionList']>>[0]
>;

export const getDesirabilityFunctionsDataProvider = (
  dataService: IMoosaDataService
): DesirabilityFunctionsDataProvider => ({
  getList: (params) => dataService.getDesirabilityFunctionList(params ?? {}),
  createItem: (payload) => dataService.createDesirabilityFunction(payload),
  getItem: (payload) => dataService.getDesirabilityFunctionById(payload),
  updateItem: (payload) => dataService.updateDesirabilityFunctionById(payload),
  deleteItem: dataService.deleteDesirabilityFunctionById,
  undeleteItem: dataService.undeleteDesirabilityFunctionById,
});
