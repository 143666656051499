import { DesirabilityFunctionType } from '@discngine/moosa-models';
import { TickFormatter } from '@visx/axis';
import { ScaleInput } from '@visx/scale';
import { TextProps } from '@visx/text/lib/Text';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BarsRerender } from 'HistogramView';

import { toPrecisionString } from '../ToPrecisionString';

import { DiscreteSlicedBar } from './DicreteSlicedBar';
import { NumericSlicedBar } from './NumericSlicedBar';
import { DiscreteHistogramData, HistogramData, NumericHistogramData } from './types';

dayjs.extend(utc);

export const NUM_TICKS_Y = 6;
export const NUM_TICKS_X = 8;
export const MARGIN = { left: 36, right: 10, top: 10, bottom: 56 };
export const TOOLTIP = {
  width: 16,
  padding: 16,
};

export const tickLabelProps = (): Partial<TextProps> => {
  return {
    dy: 8,
    angle: -45,
    fontSize: 9,
    textAnchor: 'middle',
  } as const;
};

export const tickFormatter: TickFormatter<ScaleInput<ScaleLinear<number, number>>> = (
  value
) => {
  return toPrecisionString(Number(value), 4);
};

/**
 * Format date to display on histogram.
 * All dates should be parsed as UTC dates without timezone to be compatible with backend representation
 * @param date Date instance or Unix timestamp number
 */
export const formatDateForHistogram = (date: Date | number): string => {
  return dayjs.utc(date).format('YYYY-MM-DD');
};

export const dateTickFormatter: TickFormatter<ScaleInput<ScaleLinear<number, number>>> = (
  value
) => {
  return formatDateForHistogram(value.valueOf());
};

export const getBarsRerenderForDesirabilityHistogram = (
  desirabilityType: DesirabilityFunctionType,
  histogramData?: HistogramData | null
): BarsRerender => {
  if (desirabilityType === DesirabilityFunctionType.discrete) {
    return (
      xScale: ScaleBand<any> | ScaleLinear<number, number>,
      yScale: ScaleLinear<number, number>
    ) => (
      <DiscreteSlicedBar
        data={histogramData as DiscreteHistogramData}
        xScale={xScale as ScaleBand<any>}
        yScale={yScale}
      />
    );
  } else {
    return (
      xScale: ScaleBand<any> | ScaleLinear<number, number>,
      yScale: ScaleLinear<number, number>
    ) => (
      <NumericSlicedBar
        data={histogramData as NumericHistogramData}
        xScale={xScale as ScaleLinear<number, number>}
        yScale={yScale}
      />
    );
  }
};
