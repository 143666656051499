import { useMoosaDataContext } from '@discngine/moosa-models';
import { Alert, AlertProps, Modal, message } from 'antd';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './DesirabilityFunctionSelect/DesirabilityFunctionSelect.module.less';

const WRONG_CHARACTERS = /[#$<>,.\\|/?!%+;:~`'[\]{}]/;

interface DesirabilityFunctionSaveDialogProps {
  visible: boolean;
  onSave: (name: string, existingId?: string) => Promise<void>;
  onCancel: () => void;
  functionName: string;
}

export const DesirabilityFunctionSaveDialog: React.FC<
  DesirabilityFunctionSaveDialogProps
> = ({ visible, onSave, onCancel, functionName }) => {
  const [name, setName] = useState('');
  const [input, setInput] = useState<any>(null);
  const dataService = useMoosaDataContext();
  const [isChecking, setIsChecking] = useState(false);
  const [existingId, setExistingId] = useState<string>();

  useEffect(() => {
    if (!input) {
      return;
    }

    const text = input.value;

    input.setSelectionRange(text.length - 4, text.length);
  }, [input]);

  const hasWrongChars = useMemo(() => WRONG_CHARACTERS.test(name), [name]);

  const error = useMemo(() => {
    let error: { type: AlertProps['type']; message: string } | null;

    if (existingId) {
      error = {
        type: 'warning',
        message: 'A function with the same name already exists and will be overwritten',
      };
    } else if (hasWrongChars) {
      error = {
        type: 'error',
        message: `Function name cannot contain special characters like: #$<>,.|/?!%+;:~\`'[]{}`,
      };
    } else {
      return null;
    }

    return error;
  }, [existingId, hasWrongChars]);

  const cannotSubmit = !name || hasWrongChars || error?.type === 'error' || isChecking;

  const debouncedCheckName = debounce(async (name: string) => {
    try {
      const response = await dataService.checkDesirabilityFunctionName({
        name,
      });

      setExistingId(response.success ? undefined : response.existingId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsChecking(false);
    }
  }, 500);

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleCheckName = useCallback(debouncedCheckName, []);

  const handleSave = useCallback(async () => {
    try {
      await onSave(name, existingId);
      onCancel();
    } catch (error: any) {
      message.error(error.message);
    }
  }, [onSave, onCancel, name, existingId]);

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSave();
      }
    },
    [handleSave]
  );

  const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value.trim();

    setIsChecking(true);
    setName(value);
    handleCheckName(value);
  };

  useEffect(() => {
    setName(functionName);
    handleCheckName(functionName);
  }, [functionName, visible, handleCheckName]);

  const okText = useMemo(() => {
    if (isChecking) return 'Checking...';

    if (existingId) return 'Update';

    return 'Save';
  }, [isChecking, existingId]);

  return (
    <Modal
      centered
      destroyOnClose
      okButtonProps={{ disabled: cannotSubmit }}
      okText={okText}
      open={visible}
      title="Save desirability function"
      width={400}
      onCancel={onCancel}
      onOk={handleSave}
    >
      <div className={styles.modalContainer}>
        <label>Function name</label>
        <input
          ref={setInput}
          autoFocus
          className={styles.input}
          type="text"
          value={name}
          onChange={handleNameChange}
          onKeyUp={cannotSubmit ? undefined : onKeyUp}
        />
        {error && <Alert {...error} />}
      </div>
    </Modal>
  );
};
