import { UnitStepFunctionParams } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';

import { ValueInput } from '../../ValueInput/ValueInput';

import styles from './DesirabilityCommonStyles.module.less';
import { DesirabilityFunctionTableParams } from './DesirabilityFunctionTableParams';
import { isValidValue, isValidYValue } from './isValidValue';

const UnitStepFunctionTable: FC<DesirabilityFunctionTableParams<UnitStepFunctionParams>> =
  React.memo(function UnitStepFunctionTable({ params, onParamsChange }) {
    const handleXChange = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidValue(value)) {
          onParamsChange({
            ...params,
            xStep: value,
          });
        }
      },
      [onParamsChange, params]
    );

    const handleY1Change = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidYValue(value)) {
          onParamsChange({
            ...params,
            yLeft: value,
          });
        }
      },
      [onParamsChange, params]
    );

    const handleY2Change = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidYValue(value)) {
          onParamsChange({
            ...params,
            yRight: value,
          });
        }
      },
      [onParamsChange, params]
    );

    return (
      <table>
        <thead>
          <tr className={styles.tableHeader}>
            <td>X</td>
            <td>f(X)</td>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          <tr>
            <td>
              <ValueInput value={params.xStep} onValueChange={handleXChange} />
            </td>
            <td>
              <ValueInput value={params.yLeft} onValueChange={handleY1Change} />
            </td>
          </tr>
          <tr>
            <td>
              <ValueInput
                disabled={true}
                value={params.xStep}
                onValueChange={handleXChange}
              />
            </td>
            <td>
              <ValueInput value={params.yRight} onValueChange={handleY2Change} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  });

export default UnitStepFunctionTable;
