import type { ScaleLinear, ScaleTime } from 'd3-scale';
import { useMemo } from 'react';

import { SlicedBar } from './SlicedBar';
import { DateHistogramData, NumericHistogramData } from './types';

export const NUMERIC_BAR_GAP = 2;

type PreparedNumericBars = {
  x: number;
  y: number[];
  width: number;
};

const numericHistogramDataToBars = (
  data: NumericHistogramData | DateHistogramData | null,
  xScale: ScaleLinear<number, number> | ScaleTime<number, number>
): PreparedNumericBars[] | undefined => {
  return data?.bars.map(({ x, y }) => {
    let barX0, barWidth;

    if (x[0] !== x[1]) {
      barX0 = xScale(x[0]) + NUMERIC_BAR_GAP / 2;
      const barX1 = xScale(x[1]) - NUMERIC_BAR_GAP / 2;

      barWidth = barX1 - barX0;
    } else {
      barWidth = NUMERIC_BAR_GAP * 10;
      barX0 = xScale(x[0]) - barWidth / 2;
    }

    return {
      x: barX0,
      y,
      width: barWidth,
    };
  });
};

export const NumericSlicedBar = ({
  data,
  xScale,
  yScale,
}: {
  data: NumericHistogramData | DateHistogramData | null;
  xScale: ScaleLinear<number, number> | ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) => {
  const bars = useMemo(() => {
    return numericHistogramDataToBars(data, xScale);
  }, [data, xScale]);

  return (
    <>
      {data &&
        bars?.map((bar, index) => {
          return (
            bar.width && (
              <SlicedBar
                key={index}
                colors={data.colors}
                width={bar.width}
                x={bar.x}
                y={bar.y}
                yScale={yScale}
              />
            )
          );
        })}
    </>
  );
};
