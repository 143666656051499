import {
  ColumnId,
  Condition,
  ConditionType,
  DateCondition,
  DecisionTreeDateNode,
  DTOperation,
  FieldType,
  IColumnMetaInfo,
  PortGroupingType,
  RangeCondition,
  SimpleCondition,
} from '@discngine/moosa-models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { parseUtcDatetime } from '../../../utils';

type DateNodeSettings = {
  id: string;
  name: ColumnId;
  condition: DateCondition | null;
  portGroupingType: PortGroupingType | null;
};

const defaultNodeSettings: DateNodeSettings = {
  id: '',
  name: '',
  condition: null,
  portGroupingType: PortGroupingType.Regular,
};

const getDateNodeCondition = (
  condition: DateNodeSettings['condition']
): DecisionTreeDateNode['condition'] => {
  if (condition?.type === ConditionType.Simple) {
    return {
      type: ConditionType.Simple,
      threshold: condition.threshold || 0,
      operation: condition.operation || DTOperation.Greater,
    };
  }

  if (condition?.type === ConditionType.Range) {
    return {
      type: ConditionType.Range,
      min: {
        threshold: condition.min.threshold || 0,
        operation: condition.min.operation || DTOperation.Less,
      },
      max: {
        threshold: condition.max.threshold || 0,
        operation: condition.max.operation || DTOperation.Less,
      },
    };
  }

  return null;
};

export const getNodeSettings = (node: DecisionTreeDateNode): DateNodeSettings => {
  return {
    ...defaultNodeSettings,
    id: node?.id || uuid(),
    name: node?.propertyId ?? '',
    portGroupingType: node.portGroupingType || PortGroupingType.Regular,
    condition: getDateNodeCondition(node.condition),
  };
};

export const getDateNode = (
  nodeSettings: DateNodeSettings,
  node: DecisionTreeDateNode
): DecisionTreeDateNode => {
  return {
    ...node,
    propertyId: nodeSettings.name,
    condition: getDateNodeCondition(nodeSettings.condition),
    portGroupingType: nodeSettings.portGroupingType,
  };
};

export const useDateNodeSettings = (
  nodeData: DecisionTreeDateNode,
  onNodeChange: (node: DecisionTreeDateNode) => void
) => {
  const [nodeSettings, setNodeSettings] = useState<DateNodeSettings>(
    getNodeSettings(nodeData)
  );

  useEffect(() => {
    setNodeSettings(getNodeSettings(nodeData));
  }, [nodeData]);

  const updateNodeSettings = useCallback(
    (nodeSettings) => {
      setNodeSettings(nodeSettings);
      onNodeChange(getDateNode(nodeSettings, nodeData));
    },
    [nodeData, onNodeChange]
  );

  const changedDateNode = useMemo(() => {
    return getDateNode(nodeSettings, nodeData);
  }, [nodeSettings, nodeData]);

  return {
    nodeSettings,
    updateNodeSettings,
    changedDateNode,
  };
};

// TODO fix it when we will have Date column metadata statistics
export const getDefaultDateConditionSettings = (
  metaData: IColumnMetaInfo,
  conditionType?: ConditionType,
  previous?: DateCondition | null
): Condition | null => {
  if (metaData.type !== FieldType.Date || !conditionType) {
    return null;
  }

  switch (conditionType) {
    case ConditionType.Simple: {
      const condition: SimpleCondition = {
        type: ConditionType.Simple,
        operation: DTOperation.Less,
        threshold: Date.now(),
      };

      if (previous?.type === ConditionType.Range) {
        condition.operation =
          previous.min.operation === DTOperation.Less
            ? DTOperation.Greater
            : DTOperation.GreaterEqual;
        condition.threshold = previous.min.threshold;
      }

      return condition;
    }

    // Range operations are always DTOperation.LessEqual because range is 'from...to'
    // Keep in mind that operations are for the statement 'min <= value <= max'

    case ConditionType.Range: {
      const condition: RangeCondition = {
        type: ConditionType.Range,
        min: {
          operation: DTOperation.LessEqual,
          threshold: parseUtcDatetime(Date.now()).subtract(1, 'year').valueOf(),
        },
        max: {
          operation: DTOperation.LessEqual,
          threshold: parseUtcDatetime(Date.now()).valueOf(),
        },
      };

      if (previous && previous.type === ConditionType.Simple) {
        if (
          previous.operation === DTOperation.Less ||
          previous.operation === DTOperation.LessEqual
        ) {
          condition.max = {
            operation: DTOperation.LessEqual,
            threshold: previous.threshold,
          };
        } else {
          condition.min = {
            operation: DTOperation.LessEqual,
            threshold: previous.threshold,
          };
        }
      }

      return condition;
    }

    default:
      return null;
  }
};
