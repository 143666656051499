import { classNames } from '@discngine/moosa-common';
import {
  DiscreteHistogram,
  HistogramData,
  isDiscreteHistogramData,
  isNumericHistogramData,
  NumericHistogram,
} from '@discngine/moosa-histogram';
import {
  ColumnId,
  Condition,
  ConditionType,
  DatasetRowId,
  DecisionTreeNodeResult,
  DecisionTreePropertyNode,
  FieldType,
  DTNodeType,
  IColumnLabelMap,
  IColumnMetaInfo,
  IScoringFuncProperty,
  isPropertyNodeResult,
  PortGroupingType,
  DesirabilityFunctionType,
} from '@discngine/moosa-models';
import { InfinityListProps } from '@discngine/moosa-shared-components';
import { Alert, Radio } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NodeConditionMismatch,
  NodeConditionMismatchLevel,
  NodeConditionMismatchType,
} from 'types';
import { getMismatchDescription, getTypeMismatch } from 'utils';

import { HISTOGRAM_WIDTH, HISTOGRAM_HEIGHT } from '../../constants';
import {
  NODE_CONDITION_MISMATCH_ERROR,
  NODE_CONDITION_MISMATCH_WARNING,
} from '../../lib/go/colors';

import { DesirabilityFunctionConditionInput } from './DesirabilityFunctionConditionInput';
import { DiscreteConditionInput } from './DiscreteConditionInput';
import { RangeConditionInput } from './RangeConditionInput';
import { EditorPanelHeader } from './shared/EditorPanelHeader';
import { OutputsInfo } from './shared/OutputsInfo';
import { PortGrouping } from './shared/PortGrouping';
import styles from './shared/PropertyNodeEditPanel.module.less';
import { SimpleConditionInput } from './SimpleConditionInput';
import { ConditionOption, PropertyNodeSettings } from './types';
import {
  conditionTypesToOptions,
  getDefaultConditionSettings,
  getNodeSettings,
  getPropertyNode,
  isCompleted,
} from './utils';

export interface PropertyNodeEditPanelProps {
  nodeData: DecisionTreePropertyNode;
  columns: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  desirabilityFunctionListProps?: Partial<InfinityListProps>;
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined;
  getNodeResult: (node: DecisionTreePropertyNode) => DecisionTreeNodeResult | undefined;
  onNodeChange: (node: DecisionTreePropertyNode) => void;
  onCancel: () => void;
  onReset: (node: DecisionTreePropertyNode) => void;
  onDesirabilitySave: (
    desirability: IScoringFuncProperty,
    existingId?: string
  ) => Promise<void>;
  onDesirabilitySearch: (query: string, isDiscreteStringFunction: boolean) => void;
  onDesirabilityDelete: (id: string) => Promise<void>;
  getHistogramData: (
    node: DecisionTreePropertyNode,
    rowIds: Set<DatasetRowId>
  ) => HistogramData | null;
}

export const PropertyNodeEditPanel: FC<PropertyNodeEditPanelProps> = ({
  nodeData,
  columns,
  columnLabelMap,
  desirabilityFunctionListProps,
  getMetaData,
  getNodeResult,
  getHistogramData,
  onNodeChange,
  onCancel,
  onReset,
  onDesirabilitySave,
  onDesirabilitySearch,
  onDesirabilityDelete,
}) => {
  const [nodeSettings, setNodeSettings] = useState<PropertyNodeSettings>(
    getNodeSettings(nodeData)
  );

  const updateNodeSettings = useCallback(
    (nodeSettings) => {
      setNodeSettings(nodeSettings);
      onNodeChange(getPropertyNode(nodeSettings, nodeData));
    },
    [nodeData, onNodeChange]
  );

  useEffect(() => {
    setNodeSettings(getNodeSettings(nodeData));
  }, [nodeData]);

  const nodeResult = useMemo(() => {
    if (isCompleted(nodeSettings)) {
      const result = getNodeResult(getPropertyNode(nodeSettings, nodeData));

      if (!result) return null;

      return isPropertyNodeResult(result) ? result : null;
    }
  }, [getNodeResult, nodeData, nodeSettings]);

  const histogramData = useMemo(() => {
    const rowIds = nodeResult?.inputDatasetRowIds;

    if (!rowIds) return null;

    return getHistogramData(getPropertyNode(nodeSettings, nodeData), rowIds);
  }, [getHistogramData, nodeData, nodeResult?.inputDatasetRowIds, nodeSettings]);

  const numericHistogramData = useMemo(() => {
    return histogramData && isNumericHistogramData(histogramData) ? histogramData : null;
  }, [histogramData]);

  const discreteHistogramData = useMemo(() => {
    return histogramData && isDiscreteHistogramData(histogramData) ? histogramData : null;
  }, [histogramData]);

  const metaData = getMetaData(nodeSettings.name);

  const handleConditionTypeChange = useCallback(
    (type: ConditionType) => {
      updateNodeSettings({
        ...nodeSettings,
        condition: metaData
          ? getDefaultConditionSettings(metaData, type, nodeSettings.condition)
          : null,
      });
    },
    [metaData, nodeSettings, updateNodeSettings]
  );

  const handlePortGroupingTypeChange = useCallback(
    (portGroupingType: PortGroupingType) => {
      updateNodeSettings({
        ...nodeSettings,
        portGroupingType,
      });
    },
    [nodeSettings, updateNodeSettings]
  );

  const handleConditionChange = useCallback(
    (condition?: Condition) => {
      if (condition) {
        updateNodeSettings({
          ...nodeSettings,
          condition,
        });
      }
    },
    [nodeSettings, updateNodeSettings]
  );

  const conditionTypeOptions: ConditionOption[] = useMemo(() => {
    if (!metaData) {
      if (nodeSettings.condition?.type) {
        return conditionTypesToOptions([nodeSettings.condition.type]);
      }

      return [];
    }

    if (metaData.type === FieldType.Number) {
      if (metaData.isDiscreteColumn) {
        return conditionTypesToOptions([
          ConditionType.Simple,
          ConditionType.Range,
          ConditionType.Discrete,
          ConditionType.DesirabilityFunction,
        ]);
      }

      return conditionTypesToOptions([
        ConditionType.Simple,
        ConditionType.Range,
        ConditionType.DesirabilityFunction,
      ]);
    }

    if (metaData.type === FieldType.String && metaData.isDiscreteColumn) {
      return conditionTypesToOptions([
        ConditionType.Discrete,
        ConditionType.DesirabilityFunction,
      ]);
    }

    return [];
  }, [metaData, nodeSettings.condition?.type]);

  const typeMismatch: NodeConditionMismatch | null = useMemo(() => {
    return getTypeMismatch(getPropertyNode(nodeSettings, nodeData), metaData);
  }, [metaData, nodeData, nodeSettings]);

  const disabledConditionInput = useMemo(
    () =>
      conditionTypeOptions.length === 0 ||
      typeMismatch?.level === NodeConditionMismatchLevel.Critical,
    [conditionTypeOptions.length, typeMismatch?.level]
  );

  const handleColumnChange = useCallback(
    (columnId: ColumnId) => {
      const metaData = getMetaData(columnId);

      if (!metaData) return;

      const updatedSettings = {
        ...structuredClone(nodeSettings),
        name: columnId,
      };

      if (updatedSettings.condition?.type === ConditionType.DesirabilityFunction) {
        const defaultConditionSettings = getDefaultConditionSettings(
          metaData,
          ConditionType.DesirabilityFunction
        );

        // If the default desirability condition for a new column
        // is of a different type than the current one,
        // reset the condition to default
        if (
          defaultConditionSettings &&
          defaultConditionSettings.type === ConditionType.DesirabilityFunction &&
          (defaultConditionSettings.desirability.type ===
            DesirabilityFunctionType.discrete ||
            updatedSettings.condition.desirability.type ===
              DesirabilityFunctionType.discrete)
        ) {
          updatedSettings.condition = defaultConditionSettings;
        }
      }

      updateNodeSettings(updatedSettings);
    },
    [getMetaData, nodeSettings, updateNodeSettings]
  );

  return (
    <div className={styles.wrapper}>
      <EditorPanelHeader
        columnId={nodeSettings.name}
        columnLabelMap={columnLabelMap}
        columns={columns}
        getMetaData={getMetaData}
        nodeType={DTNodeType.Property}
        onCancel={onCancel}
        onSelect={handleColumnChange}
      />

      <>
        {numericHistogramData &&
          nodeSettings.condition?.type !== ConditionType.DesirabilityFunction && (
            <NumericHistogram
              condition={
                nodeSettings.condition?.type === ConditionType.Simple ||
                nodeSettings.condition?.type === ConditionType.Range
                  ? nodeSettings.condition
                  : undefined
              }
              data={numericHistogramData}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
              onChange={handleConditionChange}
            />
          )}

        {discreteHistogramData &&
          nodeSettings.condition?.type !== ConditionType.DesirabilityFunction && (
            <DiscreteHistogram
              condition={
                nodeSettings.condition?.type === ConditionType.Discrete
                  ? nodeSettings.condition
                  : undefined
              }
              data={discreteHistogramData}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
              onChange={handleConditionChange}
            />
          )}
      </>

      {typeMismatch && (
        <div
          className={classNames(styles.typeMismatch, styles.conditionRow)}
          style={{
            backgroundColor:
              typeMismatch.level === NodeConditionMismatchLevel.Critical
                ? NODE_CONDITION_MISMATCH_ERROR
                : NODE_CONDITION_MISMATCH_WARNING,
          }}
        >
          {typeMismatch && getMismatchDescription(typeMismatch)}

          <i>Select a different column from the drop-down list above</i>

          {disabledConditionInput &&
            !(
              typeMismatch.type === NodeConditionMismatchType.MissingColumn ||
              typeMismatch.type === NodeConditionMismatchType.Text
            ) && (
              <>
                <i>
                  or reset the condition type (this will destroy the current condition)
                </i>

                <span className={styles.resetCondition} onClick={() => onReset(nodeData)}>
                  Reset condition
                </span>
              </>
            )}
        </div>
      )}

      {conditionTypeOptions.length > 0 && (
        <div className={styles.conditionRow}>
          <span>IF</span>

          <Radio.Group
            buttonStyle="solid"
            className={classNames(styles.conditionTypeSelector, {
              [styles.wrapConditionTypes]: conditionTypeOptions.length > 3,
            })}
            disabled={disabledConditionInput}
            options={conditionTypeOptions}
            optionType="button"
            value={nodeSettings.condition?.type}
            onChange={(event) => handleConditionTypeChange(event.target.value)}
          />
        </div>
      )}

      {!nodeSettings.condition && (
        <div className={styles.conditionRow}>
          <Alert
            message="Comparison condition is not specified"
            style={{ width: '100%' }}
            type="error"
          />
        </div>
      )}

      <>
        {nodeSettings.condition?.type === ConditionType.Simple && (
          <SimpleConditionInput
            condition={nodeSettings.condition}
            disabled={disabledConditionInput}
            onChange={handleConditionChange}
          />
        )}

        {nodeSettings.condition?.type === ConditionType.Range && (
          <RangeConditionInput
            condition={nodeSettings.condition}
            disabled={disabledConditionInput}
            onChange={handleConditionChange}
          />
        )}

        {nodeSettings.condition?.type === ConditionType.DesirabilityFunction &&
          metaData && (
            <DesirabilityFunctionConditionInput
              condition={nodeSettings.condition}
              desirabilityFunctionListProps={desirabilityFunctionListProps}
              disabled={disabledConditionInput}
              histogramData={histogramData}
              metaData={metaData}
              onChange={handleConditionChange}
              onDelete={onDesirabilityDelete}
              onSave={onDesirabilitySave}
              onSearch={onDesirabilitySearch}
            />
          )}

        {nodeSettings.condition?.type === ConditionType.Discrete && (
          <DiscreteConditionInput
            condition={nodeSettings.condition}
            disabled={disabledConditionInput}
            isNumeric={!metaData?.isDiscreteColumn || metaData?.type !== FieldType.String}
            metaData={metaData}
            onChange={handleConditionChange}
          />
        )}
      </>

      <OutputsInfo outputDatasetRowIds={nodeResult?.outputDatasetRowIds} />

      {nodeSettings.portGroupingType && (
        <PortGrouping
          portGroupingType={nodeSettings.portGroupingType}
          onChange={handlePortGroupingTypeChange}
        />
      )}
    </div>
  );
};
