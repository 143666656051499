import { HistogramView, BarsRerender } from '@discngine/moosa-histogram';
import {
  IColumnMetaInfo,
  IScoringFuncProperty,
  SortFunctionTableType,
} from '@discngine/moosa-models';
import { DesirabilityFunctionSingleParams } from '@discngine/moosa-models';
import { IDiscretePoint } from '@discngine/moosa-models';
import { FieldType } from '@discngine/moosa-models';
import { DesirabilityFunctionType } from '@discngine/moosa-models';
import React, { FC, useCallback, useState } from 'react';

import { ResponsiveContainer } from '../ResponsiveContainer/ResponsiveContainer';

import styles from './DesirabilityFunction.module.less';
import { DesirabilityFunctionSelect } from './DesirabilityFunctionSelect/DesirabilityFunctionSelect';
import DiscreteFunctionTable from './DiscreteValues/DiscreteFunctionTable/DiscreteFunctionTable';
import LineSegmentsFunctionTable from './ScoringFunctionTables/LineSegmentsFunctionTable';
import LogarithmicFunctionTable from './ScoringFunctionTables/LogarithmicFunctionTable';
import SigmoidFunctionTable from './ScoringFunctionTables/SigmoidFunctionTable';
import UnitStepFunctionTable from './ScoringFunctionTables/UnitStepFunctionTable';
import { InfinityListProps } from './types';

type DesirabilityFunctionProps = {
  desirability: IScoringFuncProperty;
  metadata: IColumnMetaInfo;
  onParamsChange: (param: DesirabilityFunctionSingleParams) => void;
  onAddFunctionPoint: (param: {
    valueX: number | string;
    valueY: number;
    xSort?: SortFunctionTableType;
    ySort?: SortFunctionTableType;
    originalPointIndex?: number;
    fieldType?: FieldType;
  }) => void;
  onUpdateFunctionPoint: (x: number, y: number, idx: number, id: string) => void;
  onRemoveFunctionPoint: (index: number) => void;
  onChangeScoringFunction: (desirability: IScoringFuncProperty) => void;
  onSave: (desirability: IScoringFuncProperty, existingId?: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onSearch: (query: string) => void;
  onMoveFinish: () => void;
  onDiscretePointsOrderChange: (
    columnName: string,
    fromPoint: string | number,
    toPoint: string | number
  ) => void;
  infinityListProps: InfinityListProps;
  barsRerender?: BarsRerender;
};

const DesirabilityFunction: FC<DesirabilityFunctionProps> = React.memo(
  function DesirabilityFunction({
    desirability,
    metadata,
    onParamsChange,
    onAddFunctionPoint,
    onUpdateFunctionPoint,
    onRemoveFunctionPoint,
    onChangeScoringFunction,
    onSave,
    onDelete,
    onSearch,
    onMoveFinish,
    onDiscretePointsOrderChange,
    infinityListProps,
    barsRerender,
  }) {
    const [isHistogramVisible, setIsHistogramVisible] = useState(true);
    const onToggleHistogramVisible = useCallback(() => {
      setIsHistogramVisible((isHistogramVisible) => !isHistogramVisible);
    }, []);

    const onPointsChange = useCallback(
      (points: IDiscretePoint[]) => {
        onParamsChange({ points });
      },
      [onParamsChange]
    );

    const handleAddHistogramPoint = useCallback(
      (valueX: number, valueY: number, originalPointIndex: number) => {
        if (desirability.type === DesirabilityFunctionType.custom) {
          onAddFunctionPoint({
            valueX,
            valueY,
            originalPointIndex,
            fieldType: FieldType.Number,
          });
        }
      },
      [onAddFunctionPoint, desirability.type]
    );

    const histogram = () => (
      <div className={styles.chartRoot}>
        <ResponsiveContainer
          barsRerender={barsRerender}
          component={HistogramView}
          desirability={desirability}
          metadata={metadata}
          onAddFunctionPoint={handleAddHistogramPoint}
          onMoveFinish={onMoveFinish}
          onRemoveFunctionPoint={onRemoveFunctionPoint}
          onUpdateFunctionPoint={onUpdateFunctionPoint}
        />
      </div>
    );

    return (
      <>
        <DesirabilityFunctionSelect
          desirability={desirability}
          infinityListProps={infinityListProps}
          metadata={metadata}
          onChangeScoringFunction={onChangeScoringFunction}
          onDelete={onDelete}
          onSave={onSave}
          onSearch={onSearch}
        />

        {desirability.type === DesirabilityFunctionType.discrete ? (
          <>
            <DiscreteFunctionTable
              column={desirability.column}
              isHistogramVisible={isHistogramVisible}
              metadata={metadata}
              points={
                desirability.functionParams[DesirabilityFunctionType.discrete]?.points ||
                []
              }
              onAddFunctionPoint={onAddFunctionPoint}
              onFunctionPointsChange={onPointsChange}
              onPointsOrderChange={onDiscretePointsOrderChange}
              onRemoveFunctionPoint={onRemoveFunctionPoint}
              onToggleHistogramVisible={onToggleHistogramVisible}
            />

            {isHistogramVisible && histogram()}
          </>
        ) : (
          <>
            {histogram()}

            {(desirability.type === DesirabilityFunctionType.linear ||
              desirability.type === DesirabilityFunctionType.triangular ||
              desirability.type === DesirabilityFunctionType.rectangular ||
              desirability.type === DesirabilityFunctionType.custom) && (
              <LineSegmentsFunctionTable
                metadata={metadata}
                params={desirability.functionParams[desirability.type]}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.unitStep && (
              <UnitStepFunctionTable
                metadata={metadata}
                params={desirability.functionParams.unitStep}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.logarithmic && (
              <LogarithmicFunctionTable
                metadata={metadata}
                params={desirability.functionParams.logarithmic}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.sigmoid && (
              <SigmoidFunctionTable
                metadata={metadata}
                params={desirability.functionParams.sigmoid}
                onParamsChange={onParamsChange}
              />
            )}
          </>
        )}
      </>
    );
  }
);

export default DesirabilityFunction;
