import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import React, { FC } from 'react';

import styles from './DeletePointModal.module.less';

type DeletePointModalProps = {
  isDeleteModalVisible: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const DeletePointModal: FC<DeletePointModalProps> = React.memo(
  ({ isDeleteModalVisible, onCancel, onDelete }) => {
    return (
      <Modal
        centered={true}
        closeIcon={<CloseCircleOutlined className={styles.closeIcon} />}
        footer={null}
        open={isDeleteModalVisible}
        width={382}
        onCancel={onCancel}
      >
        <Col className={styles.modal}>
          <Row>
            Are you sure you want to&nbsp;
            <span className={styles.confirmationText}>delete the point</span>?
          </Row>
          <Row className={styles.buttons}>
            <Col>
              <Button className={styles.deleteBtn} onClick={onDelete}>
                Delete
              </Button>
            </Col>
            <Col>
              <Button className={styles.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal>
    );
  }
);
