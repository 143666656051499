import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { FC, useCallback, useEffect, useState } from 'react';

type ValueInputProps = {
  value: number | string;
  onValueChange(val: string): void;
  disabled?: boolean;
  size?: SizeType;
  className?: string;
  type?: 'number' | 'text';
  addonAfter?: string;
  placeholder?: string;
};

export const ValueInput: FC<ValueInputProps> = React.memo(function ValueInput({
  value,
  onValueChange,
  disabled,
  size,
  className,
  type,
  addonAfter,
  placeholder,
}) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const resetVal = useCallback(() => {
    setVal(value);
  }, [value]);

  const handleChange = useCallback(
    (event) => {
      setVal(event.target.value);
      onValueChange(event.target.value);
    },
    [onValueChange]
  );

  return (
    <Input
      addonAfter={addonAfter}
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      size={size}
      type={type}
      value={val}
      onBlur={resetVal}
      onChange={handleChange}
    />
  );
});
