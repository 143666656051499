import { isValidNumericString } from '@discngine/moosa-common';
import {
  FieldType,
  IDiscretePoint,
  SortFunctionTableType,
} from '@discngine/moosa-models';
import { Button, Col, Input, Row } from 'antd';
import isInteger from 'lodash/isInteger';
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { isValidYValue } from '../../ScoringFunctionTables/isValidValue';

import styles from './AddValue.module.less';

type AddValueProps = {
  addFunctionPoint: (param: {
    valueX: number | string;
    valueY: number;
    xSort: SortFunctionTableType;
    ySort: SortFunctionTableType;
    originalPointIndex?: number;
    fieldType?: FieldType;
  }) => void;
  onToggleAddValueModal: (isVisible: any) => void;
  inputType: 'text' | 'number';
  points: IDiscretePoint[];
  xSort: SortFunctionTableType;
  ySort: SortFunctionTableType;
};

const AddValue: FC<AddValueProps> = ({
  addFunctionPoint,
  onToggleAddValueModal,
  points,
  inputType,
  xSort,
  ySort,
}) => {
  const [xValue, setXValue] = useState('');
  const [yValue, setYValue] = useState('');
  const [isXDirty, setXIsDirty] = useState(false);
  const [isYDirty, setYIsDirty] = useState(false);

  const { xValueIsValid, yValueIsValid, formIsValid } = useMemo(() => {
    const isUniqueXValue = points.every((point) =>
      inputType === 'number' ? +point.x !== +xValue : point.x !== xValue
    );
    const xValueIsValid = isXDirty
      ? isUniqueXValue &&
        xValue !== '' &&
        (inputType === 'text' || isInteger(Number(xValue)))
      : true;
    const yValueIsValid = isYDirty
      ? yValue !== '' && isValidYValue(Number(yValue))
      : true;
    //limit the number of values to 20
    const formIsValid =
      isXDirty && isYDirty && xValueIsValid && yValueIsValid && points!.length <= 19;

    return {
      xValueIsValid,
      yValueIsValid,
      formIsValid,
    };
  }, [inputType, isXDirty, isYDirty, points, xValue, yValue]);

  const onXChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setXValue(event.target.value);

      if (!isXDirty) {
        setXIsDirty(true);
      }
    },
    [isXDirty]
  );

  const onYChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setYValue(event.target.value);

      if (!isYDirty) {
        setYIsDirty(true);
      }
    },
    [isYDirty]
  );

  const onCloseModal = useCallback(() => {
    onToggleAddValueModal(false);
    setXValue('');
    setYValue('');
    setYIsDirty(false);
    setXIsDirty(false);
  }, [onToggleAddValueModal]);

  const onAddValue = useCallback(() => {
    const isInvalidValue = inputType === 'number' && !isValidNumericString(xValue);
    const isUnique = !points.find((point: IDiscretePoint) => point.x === xValue);

    if (isInvalidValue || !isUnique) {
      return;
    }
    addFunctionPoint({
      valueX: inputType === 'number' ? +xValue : xValue,
      valueY: +yValue,
      xSort,
      ySort,
    });
    setYIsDirty(false);
    setXIsDirty(false);
    onCloseModal();
  }, [addFunctionPoint, onCloseModal, inputType, points, xSort, xValue, ySort, yValue]);

  return (
    <Row className={styles.wrap} justify="space-between">
      <Col className={styles.column}>
        <Row align="middle" className={styles.point} wrap={false}>
          <Col>X</Col>
          <Col>
            <Input
              className={!xValueIsValid && styles.invalid}
              type={inputType}
              value={xValue}
              onChange={onXChange}
            />
          </Col>
        </Row>
        <Row>
          <Button disabled={!formIsValid} onClick={onAddValue}>
            Add
          </Button>
        </Row>
      </Col>
      <Col className={styles.column}>
        <Row align="middle" className={styles.point} wrap={false}>
          <Col>f(X)</Col>
          <Col>
            <Input
              className={!yValueIsValid && styles.invalid}
              type="number"
              value={yValue}
              onChange={onYChange}
            />
          </Col>
        </Row>
        <Row>
          <Button className={styles.cancelBtn} onClick={onCloseModal}>
            Cancel
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default AddValue;
