import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { ScaleLinear } from 'd3-scale';
import { FC } from 'react';

import { HISTOGRAM_BAR } from '../colors';

import { GroupColor } from './types';

interface SlicedBarProps {
  x: number;
  y: number[];
  width: number;
  colors: GroupColor[];
  yScale: ScaleLinear<number, number>;
}

export const SlicedBar: FC<SlicedBarProps> = ({ x, y, width, yScale, colors }) => {
  let maxHeight = yScale(0);
  let maxY = maxHeight;

  return (
    <Group>
      {y.map((y, index) => {
        const barHeight = maxHeight - yScale(y);
        const barY = maxY - barHeight;

        maxY = barY;

        if (barHeight < 0) return null;

        return (
          <Bar
            key={`bar-${x}-${index}`}
            fill={colors[index]?.color || HISTOGRAM_BAR}
            height={barHeight}
            width={width}
            x={x}
            y={barY}
          />
        );
      })}
    </Group>
  );
};
