import { DependencyList, RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (
  ref: RefObject<HTMLDivElement>,
  deps: DependencyList = []
) => {
  const [isOverflow, setIsOverflow] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);

  return isOverflow;
};
