import { DTGroupOption } from '@discngine/moosa-models';

export type GroupColor = {
  group: DTGroupOption['name'];
  color: string;
};
export type HistogramType = 'numeric' | 'discrete' | 'date';

export interface CommonHistogramData {
  type: HistogramType;
  colors: GroupColor[];
}

export type NumericBar = {
  x: [number, number];
  y: number[];
};

export interface NumericHistogramData extends CommonHistogramData {
  type: 'numeric';
  bars: NumericBar[];
}

export interface DateHistogramData extends CommonHistogramData {
  type: 'date';
  bars: NumericBar[];
}

export type DiscreteBar = {
  x: string;
  y: number[];
};
export interface DiscreteHistogramData extends CommonHistogramData {
  type: 'discrete';
  bars: DiscreteBar[];
}

export type HistogramData =
  | NumericHistogramData
  | DiscreteHistogramData
  | DateHistogramData;

export function isNumericHistogramData(
  data: HistogramData
): data is NumericHistogramData {
  return data.type === 'numeric';
}

export function isDiscreteHistogramData(
  data: HistogramData
): data is DiscreteHistogramData {
  return data.type === 'discrete';
}

export function isDateHistogramData(data: HistogramData): data is DateHistogramData {
  return data.type === 'date';
}
