import { calculateHistogramTicks } from '@discngine/moosa-common';
import {
  DesirabilityFunctionType,
  IColumnMetaInfo,
  IHistogramBar,
  ILineDiscretePoint,
  ILinePoint,
  IScoringFuncProperty,
  SCORING_FUNCTIONS_RULES,
} from '@discngine/moosa-models';
import { AxisLeft, AxisRight, AxisBottom } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { Drag } from '@visx/drag';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import type { ScaleLinear } from 'd3-scale';
import { HistogramBar } from 'HistogramBar';
import truncate from 'lodash/truncate';
import React, { FC, useCallback, useMemo, useReducer } from 'react';

import styles from './HistogramView.module.less';
import { BarsRerender } from './types';
import { usePoints } from './usePoints';

const NUM_TICKS = 5;
const MARGIN = { left: 36, right: 36, top: 10, bottom: 36 };

const DISCRETE_X_PADDING = 0.25;

const desirabilityScaleFormatter = new Intl.NumberFormat('en-En', {
  style: 'decimal',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

type BarsRerenderDefaultProps = {
  bars: IHistogramBar[];
  xScale: ScaleLinear<number, number>;
  yMax: number;
  yHistogramScale: ScaleLinear<number, number>;
};

const barsRerenderDefault = ({
  bars,
  xScale,
  yMax,
  yHistogramScale,
}: BarsRerenderDefaultProps) => {
  return bars.map((bar) => {
    return (
      <HistogramBar
        key={bar.x}
        bar={bar}
        xScale={xScale}
        yMax={yMax}
        yScale={yHistogramScale}
      />
    );
  });
};

type HistogramViewProps = {
  desirability: IScoringFuncProperty;
  metadata: IColumnMetaInfo;
  points?: any;
  height: number;
  width: number;
  isStatic?: boolean;
  onAddFunctionPoint: (x: number, y: number, idx: number) => void;
  onUpdateFunctionPoint: (x: number, y: number, idx: number, id: string) => void;
  onRemoveFunctionPoint: (index: number) => void;
  onMoveFinish: () => void;
  barsRerender?: BarsRerender;
};

export const HistogramView: FC<HistogramViewProps> = React.memo(
  ({
    desirability,
    metadata,
    points,
    height,
    width,
    isStatic,
    onAddFunctionPoint,
    onUpdateFunctionPoint,
    onRemoveFunctionPoint,
    onMoveFinish,
    barsRerender,
  }) => {
    const isDiscreteColumn = metadata.isDiscreteColumn;
    const type = desirability.type;
    const params = desirability.functionParams[type] as any;

    const isDiscreteDesirabilityType = type === DesirabilityFunctionType.discrete;

    const discretePoints: ILineDiscretePoint[] = useMemo(() => {
      if (isDiscreteColumn && points) {
        return points;
      } else if (isDiscreteColumn) {
        return (
          desirability.functionParams[DesirabilityFunctionType.discrete]?.points ?? []
        );
      } else {
        return [];
      }
    }, [desirability.functionParams, isDiscreteColumn, points]);

    const { discreteTicks, discreteBars, discreteMaxBar } = useMemo(() => {
      return {
        discreteTicks: discretePoints.map((point: ILineDiscretePoint) => {
          return point.x;
        }),
        discreteBars: discretePoints.map((point, index: number) => ({
          x0: index - DISCRETE_X_PADDING,
          x: index + DISCRETE_X_PADDING,
          y0: 0,
          y: point.count!,
        })),
        // assign 1 to the discreteMaxBar in the case, when we replace absented in the dataset column
        // with the another one existed in the dataset column
        // in this case every point has count === 0, which leads to an errors, when create histogram axios
        discreteMaxBar: Math.max(...discretePoints.map((point) => point.count!)) || 1,
      };
    }, [discretePoints]);

    const maxHistogram = useMemo(() => {
      const data = isDiscreteDesirabilityType ? discreteMaxBar : metadata.histogramMax;

      return calculateHistogramTicks(data).at(-1) ?? 0;
    }, [discreteMaxBar, isDiscreteDesirabilityType, metadata.histogramMax]);

    const range = useMemo(() => {
      if (isDiscreteDesirabilityType) {
        return {
          min: -0.5,
          max: points ? points.length - 0.5 : params.points.length - 0.5,
        };
      }

      return SCORING_FUNCTIONS_RULES[type].getRange(params, metadata);
    }, [isDiscreteDesirabilityType, type, params, metadata, points]);

    const yMax = height - MARGIN.bottom;
    const xMax = width - MARGIN.right;

    const yHistogramScale = useMemo(
      () => scaleLinear({ range: [MARGIN.bottom, yMax], domain: [maxHistogram, 0] }),
      [maxHistogram, yMax]
    );

    const yDesirabilityScale = useMemo(
      () => scaleLinear({ range: [MARGIN.bottom, yMax], domain: [maxHistogram, 0] }),
      [maxHistogram, yMax]
    );

    const xScaleLinear = useMemo(
      () => scaleLinear({ range: [MARGIN.left, xMax], domain: [range.min, range.max] }),
      [xMax, range.min, range.max]
    );

    const xScaleDiscreteAxis = scaleBand({
      range: [MARGIN.left, xMax],
      domain: discreteTicks,
    });

    const linePoints = useMemo(() => {
      const points = SCORING_FUNCTIONS_RULES[type].toLine(params, range) as Array<
        (ILinePoint | ILineDiscretePoint) & { id?: string }
      >;

      return points.map((point) => ({
        x: xScaleLinear(Number(point.x)),
        y: yDesirabilityScale(point.y * maxHistogram),
        id: point.id,
        opacity: !point.mark ? 0 : 1, // hide mark sign
        originalPointIndex: point.originalPointIndex,
      }));
    }, [type, params, range, xScaleLinear, yDesirabilityScale, maxHistogram]);

    const { onDragStart, onPointAdd, onDragMove, onPointDelete, onDragEnd } = usePoints({
      xScale: xScaleLinear,
      yScale: yDesirabilityScale,
      add: onAddFunctionPoint,
      move: onUpdateFunctionPoint,
      remove: onRemoveFunctionPoint,
      maxIndex: linePoints.length,
      isEditable: type === DesirabilityFunctionType.custom,
      yMultiplier: maxHistogram,
    });
    const [state, increment] = useReducer((x) => x + 1, 0); // to rerender a dragged circle with updated state

    const onDragEndOutside = useCallback(
      (event: PointerEvent) => {
        event.stopPropagation(); // to prevent point adding after click/drag
        onMoveFinish();
        setTimeout(() => {
          onDragEnd();
          increment();
        }, 0);
        document.removeEventListener('pointerup', onDragEndOutside);
      },
      [onDragEnd, onMoveFinish]
    );

    const xScale = useMemo(
      () => (isDiscreteDesirabilityType ? xScaleDiscreteAxis : xScaleLinear),
      [isDiscreteDesirabilityType, xScaleDiscreteAxis, xScaleLinear]
    );

    const bars = isDiscreteDesirabilityType ? discreteBars : metadata.histogramBars;

    return (
      <svg
        className={styles.plot}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Group left={MARGIN.left}>
          <AxisLeft hideZero numTicks={NUM_TICKS} scale={yHistogramScale} />
        </Group>
        <Group left={width - MARGIN.right}>
          <AxisRight
            numTicks={NUM_TICKS}
            scale={yDesirabilityScale}
            tickFormat={(value) =>
              desirabilityScaleFormatter.format(Number(value) / maxHistogram)
            }
          />
        </Group>
        <Group top={yMax}>
          <AxisBottom
            numTicks={NUM_TICKS}
            scale={xScaleLinear}
            tickLabelProps={() => ({
              angle: -45,
              fontSize: 10,
              textAnchor: 'middle',
            })}
            {...(isDiscreteDesirabilityType && {
              scale: xScaleDiscreteAxis,
              tickSize: 0,
              numTicks: discreteTicks.length,
            })}
            tickComponent={({ formattedValue, ...props }) => (
              <text
                {...props}
                dy="0.45em"
                transform={`rotate(${props.angle}, ${props.x}, ${props.y})`}
              >
                {formattedValue && formattedValue?.length > 7 && (
                  <title>{formattedValue}</title>
                )}
                {truncate(String(formattedValue), { length: 7 })}
              </text>
            )}
          />
        </Group>

        {barsRerender
          ? barsRerender(xScale, yHistogramScale)
          : barsRerenderDefault({ bars, xScale: xScaleLinear, yMax, yHistogramScale })}

        <LinePath
          curve={curveLinear}
          data={linePoints}
          stroke="red"
          strokeWidth={3}
          x={(data) => data.x}
          y={(data) => data.y}
        />

        <rect
          fill="transparent"
          height={Math.max(yMax, 0)}
          width={Math.max(xMax, 0)}
          x={MARGIN.left}
          y={MARGIN.top}
          onMouseUp={onPointAdd}
        />

        {!isStatic &&
          linePoints.map((item) => {
            if (item.originalPointIndex === undefined) {
              return null;
            }

            return (
              <Drag
                key={`drag-${type}-${item.id}-${state}-${item.originalPointIndex}`}
                height={height}
                width={width}
                x={Number(item.x)}
                y={item.y}
                onDragEnd={(event) => {
                  document.removeEventListener('pointerup', onDragEndOutside); // drag was handled by drag element, so we don't need to handle it outside SVG
                  event.event.stopPropagation(); // to prevent point adding after click/drag
                  onDragEnd();
                  onMoveFinish();
                  onPointDelete(event, item);
                  increment();
                }}
                onDragMove={onDragMove}
                onDragStart={(event) => {
                  onDragStart(event, item);
                  document.addEventListener('pointerup', onDragEndOutside);
                }}
              >
                {({ dragStart, dragEnd, dragMove, x = 0, y = 0, dx, dy }) => {
                  const boundedY = Math.max(MARGIN.bottom, y); // sometimes y is less than minimal value

                  const xBoundary = Math.min(xMax, Math.max(xMax, MARGIN.left, x + dx));

                  const yBoundary = Math.min(
                    yMax,
                    Math.max(yMax, MARGIN.bottom, boundedY + dy)
                  );

                  const finalDx =
                    xBoundary === x ||
                    item.x === x ||
                    x + dx < xBoundary ||
                    x + dx > xBoundary
                      ? 0
                      : dx;
                  const finalDy =
                    yBoundary === boundedY ||
                    item.y === boundedY ||
                    boundedY + dy < yBoundary ||
                    boundedY + dy > yBoundary
                      ? 0
                      : dy;

                  return (
                    <circle
                      key={`dot-${item.id}-${type}`}
                      cx={x}
                      cy={boundedY}
                      {...(item.opacity === 0 && { style: { pointerEvents: 'none' } })}
                      fill="transparent"
                      opacity={item.opacity}
                      r={5}
                      stroke="blue"
                      strokeWidth={3}
                      transform={`translate(${finalDx}, ${finalDy})`}
                      onMouseDown={dragStart}
                      onMouseMove={dragMove}
                      onMouseUp={dragEnd}
                      onTouchEnd={dragEnd}
                      onTouchMove={dragMove}
                      onTouchStart={dragStart}
                    />
                  );
                }}
              </Drag>
            );
          })}
      </svg>
    );
  }
);
