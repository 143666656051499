import { SigmoidFunctionParams } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';

import { ValueInput } from '../../ValueInput/ValueInput';

import styles from './DesirabilityCommonStyles.module.less';
import { DesirabilityFunctionTableParams } from './DesirabilityFunctionTableParams';
import { isValidValue } from './isValidValue';

const SigmoidFunctionTable: FC<DesirabilityFunctionTableParams<SigmoidFunctionParams>> =
  React.memo(function SigmoidFunctionTable({ params, onParamsChange }) {
    const handleLChange = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidValue(value) && value >= -1 && value <= 1) {
          onParamsChange({
            ...params,
            l: value,
          });
        }
      },
      [onParamsChange, params]
    );

    const handleKChange = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidValue(value) && value > 0) {
          onParamsChange({
            ...params,
            k: value,
          });
        }
      },
      [onParamsChange, params]
    );

    const handleXCenterChange = useCallback(
      (val: string) => {
        const value = val ? Number(val) : NaN;

        if (isValidValue(value)) {
          onParamsChange({
            ...params,
            xCenter: value,
          });
        }
      },
      [onParamsChange, params]
    );

    return (
      <table>
        <thead>
          <tr className={styles.tableHeader}>
            <td>Param</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          <tr>
            <td>L</td>
            <td>
              <ValueInput value={params.l} onValueChange={handleLChange} />
            </td>
          </tr>
          <tr>
            <td>K</td>
            <td>
              <ValueInput value={params.k} onValueChange={handleKChange} />
            </td>
          </tr>
          <tr>
            <td>X0</td>
            <td>
              <ValueInput value={params.xCenter} onValueChange={handleXCenterChange} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  });

export default SigmoidFunctionTable;
