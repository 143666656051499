import { FunctionOutlined, SaveOutlined } from '@ant-design/icons';
import {
  DesirabilityFunctionType,
  FieldType,
  IColumnMetaInfo,
  IDesirabilityFunction,
  IScoringFuncProperty,
  SCORING_FUNCTIONS,
  SCORING_FUNCTIONS_RULES,
} from '@discngine/moosa-models';
import { Button, Select, Tooltip } from 'antd';
import { DesirabilityFunctionTemplatesListDialog } from 'DesirabilityFunction/DesirabilityFunctionTemplatesListDialog';
import { InfinityListProps } from 'DesirabilityFunction/types';
import React, { useCallback, useMemo, useState } from 'react';

import columnStyles from '../ColumnPropertyPanel.module.less';
import { DesirabilityFunctionSaveDialog } from '../DesirabilityFunctionSaveDialog';
import { getIcon } from '../helpers';

import styles from './DesirabilityFunctionSelect.module.less';

export const DesirabilityFunctionSelect: React.FC<{
  desirability: IScoringFuncProperty;
  metadata: IColumnMetaInfo;
  onChangeScoringFunction: (desirability: IScoringFuncProperty) => void;
  onSave: (desirability: IScoringFuncProperty, existingId?: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onSearch: (query: string) => void;
  infinityListProps: InfinityListProps;
}> = React.memo(
  ({
    desirability,
    metadata,
    onChangeScoringFunction,
    onSave,
    onDelete,
    onSearch,
    infinityListProps,
  }) => {
    const [templateName, setTemplateName] = useState<string>();

    const [listDialogVisible, setListDialogVisible] = useState(false);
    const [saveDialogVisible, setSaveDialogVisible] = useState(false);

    const handleSave = useCallback(
      async (name: string, id?: string) => {
        await onSave({ ...desirability, name }, id);
        setTemplateName(name);
      },
      [desirability, onSave]
    );

    const typeOptions = useMemo(() => {
      let options = [];

      if (metadata.isDiscreteColumn && metadata.type === FieldType.String) {
        options = SCORING_FUNCTIONS.filter(
          (func) => func.type === DesirabilityFunctionType.discrete
        );
      } else if (!metadata.isDiscreteColumn) {
        options = SCORING_FUNCTIONS.filter(
          (func) => func.type !== DesirabilityFunctionType.discrete
        );
      } else {
        options = SCORING_FUNCTIONS;
      }

      return options.map(({ name, type }) => ({
        label: (
          <div className={styles.selectItem}>
            {name}
            {getIcon(type)}
          </div>
        ),
        value: type,
      }));
    }, [metadata.isDiscreteColumn, metadata.type]);

    const isSaveDisabled = useMemo(() => {
      if (desirability.type !== DesirabilityFunctionType.discrete) {
        return false;
      }

      const funcParams = desirability.functionParams[DesirabilityFunctionType.discrete];

      if (funcParams?.points.length === 0) {
        return true;
      }

      if (SCORING_FUNCTIONS_RULES.discrete.toServerTemplate(funcParams).length === 0) {
        return true;
      }

      return false;
    }, [desirability.functionParams, desirability.type]);

    const handleChangeType = useCallback(
      (type: DesirabilityFunctionType) => {
        const name = SCORING_FUNCTIONS.find((item) => item.type === type)?.name;
        const params = SCORING_FUNCTIONS_RULES[type].init(metadata) as any;

        if (!name || !params) return;

        const updated = structuredClone(desirability);

        updated.name = name;
        updated.type = type;
        updated.functionParams[updated.type] = params;

        onChangeScoringFunction(updated);
        setTemplateName(undefined);
      },
      [desirability, metadata, onChangeScoringFunction]
    );

    const onDesirabilityFunctionTemplateSelect = useCallback(
      (newDesirability: IDesirabilityFunction) => {
        const updated = structuredClone(desirability);

        updated.name = newDesirability.name;
        updated.type = newDesirability.type;
        updated.isDiscreteStringFunction = newDesirability.isDiscreteStringFunction;
        updated.functionParams[updated.type] = SCORING_FUNCTIONS_RULES[
          updated.type
        ].fromServerTemplate(newDesirability.points, metadata) as any;

        onChangeScoringFunction(updated);
        setTemplateName(newDesirability.name);
      },
      [desirability, metadata, onChangeScoringFunction]
    );

    return (
      <>
        <div className={styles.selectRow}>
          <div className={columnStyles.propertyLabel} style={{ marginRight: 8 }}>
            Type:
          </div>

          <Select
            className={styles.select}
            options={typeOptions}
            size="small"
            value={desirability.type}
            onChange={handleChangeType}
          />

          <Tooltip
            overlayInnerStyle={{ borderRadius: '4px' }}
            title="Custom desirability functions"
          >
            <Button
              className={styles.button}
              icon={<FunctionOutlined />}
              shape="circle"
              size="small"
              type="text"
              onClick={() => setListDialogVisible(true)}
            />
          </Tooltip>

          <Tooltip
            overlayInnerStyle={{ borderRadius: '4px' }}
            title="Save as a custom function"
          >
            <Button
              className={styles.button}
              disabled={isSaveDisabled}
              icon={<SaveOutlined />}
              shape="circle"
              size="small"
              type="text"
              onClick={() => setSaveDialogVisible(true)}
            />
          </Tooltip>
        </div>

        {templateName && (
          <div style={{ color: '#999', fontSize: 12 }}>
            Created from: <i>{templateName}</i>
          </div>
        )}

        <DesirabilityFunctionSaveDialog
          functionName={desirability.name || ''}
          visible={saveDialogVisible}
          onCancel={() => setSaveDialogVisible(false)}
          onSave={handleSave}
        />

        <DesirabilityFunctionTemplatesListDialog
          infinityListProps={infinityListProps}
          metadata={metadata}
          visible={listDialogVisible}
          onCancel={() => setListDialogVisible(false)}
          onDelete={onDelete}
          onSearch={onSearch}
          onSelect={onDesirabilityFunctionTemplateSelect}
        />
      </>
    );
  }
);
