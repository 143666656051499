import {
  IDatasetMetaStatistics,
  ITextCategories,
} from '../../serverModels/IDatasetMetaModel';
import { Formula } from '../../serverModels/IFormula';

import { IHistogramBar } from './tableInfo';

export interface DatasetMut {
  _id: string;
  name: string;
  description: string;
  columns: DatasetMutColumn[];
  columnGroups?: ColumnGroup[];
  rows: DatasetMutRow[];

  missingValues: number;
  deletedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  rowCount: number;
}

export type DatasetMutRowId = string & { _type?: 'DatasetMutRowId' };
export type DatasetMutColumnId = string & { _type?: 'DatasetMutColumnId' };
export type DatasetMutColumnGroupId = string & {
  _type?: 'DatasetMutColumnGroupId';
};

export interface DatasetMutRow {
  _id: DatasetMutRowId;
  // tags: string[];
  properties: Record<DatasetMutColumnId, DatasetMutCell | null>; // null - missing value
}

export type DatasetMutCellValue = string | number | boolean | Date | object;

export interface DatasetMutCell<T = DatasetMutCellValue> {
  v: T;
  q?: string; // Qualifier --?? > 1000 - ignore qualifier, treat as exact value
  u?: string; // Unit - redundant?
  a?: string; // aggregation
  raw?: T[];
}

export enum DatasetMutColumnType {
  CID = 'CID', // required
  string = 'string',
  number = 'number',
  arrayOfNumber = 'arrayOfNumber',
  boolean = 'boolean',
  datetime = 'datetime',
  molecule = 'molecule',
  tags = 'tags',
}

export type ColumnTypeToDataMapper = {
  [DatasetMutColumnType.molecule]: string | null;
  [DatasetMutColumnType.number]: number | null;
  [DatasetMutColumnType.string]: string | null;
  [DatasetMutColumnType.CID]: string | number;
  [DatasetMutColumnType.datetime]: number | null;
  [DatasetMutColumnType.boolean]: boolean | null;
  [DatasetMutColumnType.arrayOfNumber]: number[] | null;
  [DatasetMutColumnType.tags]: string[] | null;
};

/**
 * This type works as validator to check that ColumnTypeToDataMapper covers all DatasetMutColumnType
 */
export type AllColumnTypes = ColumnTypeToDataMapper[DatasetMutColumnType];

export interface ColumnGroup {
  id: DatasetMutColumnGroupId;
  name: string;
  // columnKeys: string[];
}

export interface DatasetMutColumn<T extends DatasetMutColumnType = DatasetMutColumnType> {
  id: DatasetMutColumnId;
  label: string;
  columnGroupId?: DatasetMutColumnGroupId;
  type: T;
  uom?: string; // Unit of measurment

  // columns relations
  predictedColumnId?: DatasetMutColumnId; // Association between experiments and predictions
  // isError for column: columnId

  // statistics
  statistics?: IDatasetMetaStatistics; // only for number
  histogram: IHistogramBar[];

  isDiscrete: boolean;
  discreteStatistics?: {
    // only for discrete column
    textCategories: ITextCategories[] | null; // todo remove discrete statistics from statistics
  };

  // computed
  isComputed: boolean;
  formula?: Formula;

  //editing;
  isEditable: boolean;
}
