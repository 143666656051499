import { Slider } from 'antd';
import { SliderMarks } from 'antd/es/slider';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

const SELECTED_MARK_STYLE = { fontSize: '14px', fontWeight: 500 };

type ImportanceSliderViewProps = {
  weight: number;
  onWeightChange(weight: number): void;
};

export const ImportanceSliderView: FC<ImportanceSliderViewProps> = React.memo(
  function ImportanceSliderView({ weight, onWeightChange }) {
    const [sliderValue, setSliderValue] = useState(weight);

    const onValueChange = useCallback(
      (val) => {
        const value = Number(val) || 0;
        const weight = value > 10 ? 10 : value < 1 ? 1 : value;

        setSliderValue(weight);
      },
      [setSliderValue]
    );

    const marks = useMemo(() => {
      const sliderMarks: SliderMarks = {};

      for (let i = 1; i <= 10; i++) {
        sliderMarks[i] = {
          label: String(i),
          style: weight === i ? SELECTED_MARK_STYLE : {},
        };
      }

      return sliderMarks;
    }, [weight]);

    useEffect(() => {
      setSliderValue(weight);
    }, [weight]);

    return (
      <Slider
        included={true}
        marks={marks}
        max={10}
        min={1}
        tooltip={{ open: false }}
        value={sliderValue}
        onAfterChange={onWeightChange}
        onChange={onValueChange}
      />
    );
  }
);
